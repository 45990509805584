.addUserHeadline {
  font-size: 18pt;
  text-align: center;
  margin-bottom: 10px;
  color: #999999;
}

.userListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  .userNameContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;

    .userName_FullName {
      font-size: 15pt;
      user-select: none;
      margin: 0;
      margin-bottom: -5px;
    }
    .userName_ProfileName {
      font-size: 10pt;
      color: #999999;
      user-select: none;
    }
  }
}
