.newDeviceModalContainer {
  width: 100%;
  height: 100%; /* Full height of modal body */
  display: flex;
  flex-direction: column;

  .usersAndAppsCardContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    height: 100%;

    .AppListsCard,
    .usersCard {
      flex: 1; /* Expand within the row */
      width: 100%;
      height: 100%;
    }
  }
}
