.userOuterContainer {
  flex: 1;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  position: relative;

  .userAssignedContainer {
    height: 100%;
    max-height: 350px;
    overflow-y: scroll;

    .userAssignedItem {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;

      .usersAssignedDetails {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .avatarContainer {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: 2px solid white;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0;

        .avatarInitals {
          font-size: 18pt;
          font-weight: bold;
          margin-top: 4px;
          padding: 0;
          color: white;
        }
      }
    }
  }

  .addUserBtnContainer {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
