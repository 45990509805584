/* .moduleMainBoard {
  padding: 80px 0 0 10vw;
} */

/* .moduleMainBoard {
  padding: 0px 0 0 170px;
} */

/* .moduleMainBoard.collapse {
  padding: 0px 0 0 4vw;
} */

/* ----- */

.module-grid-item {
  border: 1px solid #bcbec0;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  box-shadow: 1px 1px 5px #ffffff;
  scale: 1;
  transition: all ease 0.3s;
  height: 100%;
}

.module-grid-item:hover {
  box-shadow: 5px 5px 5px #cecece;
  scale: 1.01;
}

.module-grid-item-img-container {
  position: relative;
}

.module-grid-item-img-container img {
  width: 100%;
  border-radius: 5px;
}

.module-grid-item-icon-container {
  position: absolute;
  left: 0;
  bottom: -10px;
  z-index: 1;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 2px 2px 5px #cecece;
  width: 55px;
  height: 55px;
}

.module-grid-item-icon-container img {
  width: 45px;
  height: 45px;
}

.module-body-text {
  padding: 10px 0px;
}

.module-hide .gray-out-button{
  opacity: 0.1;
}

.white-text-fix * {
  color: white;
}
.white-text-fix {
  margin: auto auto auto 90%;
}

.header-btn-modules {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 50px 20px 0;
  margin-top: -60px;
}