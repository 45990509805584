.noDataAssignedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  .noDataAssignedText {
    font-size: 16pt;
  }
}
